import * as React from "react"
import '../styles/normalize.css'
import Logo from '../images/Logo.svg'
// import Cube from '../images/BlockComCube.jpg'
import CubeVid from '../images/privacyCompute.mp4'
import CubeVidWebM from '../images/privacyCompute.webm'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'


const sm = '640px';
const md = '768px';
const lg = '1024px';
const xl = '1280px';
const xxl = '1536px';


const BlockMain = styled.main`
  display: flex;
  flex:1;
  flex-direction: column;
  background: #000;
  color: #fff;
  font-family: system, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif, serif;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
`
const BlockContent = styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
  box-sizing: border-box;
  flex-direction: column-reverse;
  padding-bottom: 80px;
  @media (min-width: ${lg}) {
    flex-direction: row;
    padding-bottom: 0;
  }
`
const BlockHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 32px;
  box-sizing: border-box;
  img{
    height: 40px !important;
    width: auto !important;
  }
`
const BlockCol = styled.div`
  width: 100%;
  display: flex;
  flex:1;
  padding: 0 32px;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  @media (min-width: ${lg}) {
    padding: 0 40px;
    width: 50%;
  }
  img, video{
    width: 100%;
    height: auto;
    max-width: 300px;
    padding-top: 80px;
    @media (min-width: ${lg}) {
      padding-top: 0;
      max-width: 400px;
    }
  }
  h1{
    font-family: 'Söhne Breit Test',system, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif, serif;
    line-height: 1.25em;
    font-weight: 600;
    font-size: 24px;
    max-width: 540px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (min-width: ${md}) {
      font-size: 40px;
    }
  }
  p{
    font-family: 'Söhne Test',system, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif, serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5em;
    color: #DBDBDB;
    max-width: 540px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 0.5em;
    letter-spacing: 0.01em;
  }
`
const BlockNav = styled.nav`
  padding: 24px 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 540px;
  @media (min-width: ${lg}) {
    position: absolute;
    padding: 24px 32px;
    width: auto;
    right: 0;
    top: 0;
  }
`
const LinkButton = styled.a`
  font-family: 'Söhne Test',system, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 12px;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  transition: opacity 0.25s ease;
  opacity:1;
  color: ${props => props.outline ? "#fff" : "#000"};
  background: ${props => props.outline ? "transparent" : "#fff"};
  border: 1px solid #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:first-child{
    margin-right: 16px;
  }
  &:hover{
    opacity: 0.5;
  }
`


// markup
const IndexPage = () => {
  return (
    <BlockMain>
      <Helmet>
        {/* General tags */}
        <title>BlockCom</title>
        <meta name="description" content="Driving decentralization through compliance" />
      </Helmet>
      <BlockHeader>
        <img src={Logo} alt="BlockCom" />
      </BlockHeader>
      <BlockContent>
        <BlockCol>
            <h1>
              Driving decentralization through compliance
            </h1>
            <BlockNav>
              {/* <LinkButton href="#" outline>Download whitepaper</LinkButton> */}
              <LinkButton href="https://forms.gle/nXa6pqeUA16pgJPx6">Become a member</LinkButton>
            </BlockNav>
            <p>
              The BlockCom Association's mission is to deliver the data compliance layer of the next generation of the internet. The goal of the Association is to foster the adoption of blockchain technology by facilitating its deployment in compliance with international data regulations and accelerate decentralization across industries with a particular focus on sectors that leverage consumer data. 
            </p>
            <p>
              The Association's objective is to foster dialogue among stakeholders about the development of compliance products, services, and best practices for distributed ledger and blockchain technologies, and create industry standards that facilitate, as well as reduce cost and complexity of the adoption of distributed ledger and blockchain technologies. 
            </p>
        </BlockCol>
        <BlockCol>
          {/* <img src={Cube} alt="BlockCom Cube" /> */}
          <video loop muted autoPlay playsInline>
            <source src={CubeVid} type="video/mp4"></source>
            <source src={CubeVidWebM} type="video/webm"></source>
          </video>
        </BlockCol>
      </BlockContent>
    </BlockMain>
  )
}

export default IndexPage
